export const baseUrl = (key) => {
  const region = localStorage.getItem("region");

  const url = process.env[key];
  if (url?.includes("localhost")) return url;
  if (region && region !== "GULF") {
    const [protcol, path] = url.split("://");
    return `${protcol}://${region.toLocaleLowerCase()}-${path}`;
  }
  return url;
};


export const getFlag = () => {
  return localStorage.getItem('region') === 'IN'
  ? 'https://storage.googleapis.com/dev-bucket.stylifashion.com/flags/flag_in.png'
  : 'https://storage.googleapis.com/dev-bucket.stylifashion.com/GCC_Flag.svg';
}

export const getPods = () => {
  return localStorage.getItem('region') === 'IN' ? 'IND' : 'GCC';
}

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem('styli_sso_user') != null
        ? JSON.parse(localStorage.getItem('styli_sso_user'))
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  return user;
};

export const setCurrentUser = (user) => {
  try {
    if (user) {
      localStorage.setItem('styli_sso_user', JSON.stringify(user));
    } else {
      localStorage.removeItem('styli_sso_user');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

